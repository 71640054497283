.ionAvatar{
    height: 100px;
}

.submitButton{
    text-align: center;
}




#cool-button-div {
    position: center;
    width:200px;
    margin:auto;
    margin-top:10vh;
    margin-left: 50px;
    margin-right: auto;
    text-align: center;
}

.ion-text-center{
    border: 0;
}

.no-border{
    border: 0;
}


ion-item{
    --border-width: 0 0 0 0
}
.outter-card{
    margin-left: auto;
    margin-right: auto;
      max-width: 400px;
  }

  .center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 30%;
  }

  .amplify-s3-imageClass {
    --height: 70px;
    --width: 70px;

  }

  .red{
      color: red;
  }
  ion-card{
    margin-left: auto;
    margin-right: auto;
      max-width: 400px;

  }

  ion-modal {
    z-index: 10000000 !important;
  }

  .blue{
    color: blue;
}

.green{
    color: green;
}

.thumb_container1 {
    -webkit-border-radius:75px;
    -moz-border-radius:75px;
    border-radius:75px;
    border:1px dotted green;  
    float:left;
    overflow:hidden;
    position: relative;
    right:10px
    
    
    
}

.thumb1{
    vertical-align:bottom;
    line-height: 10px
    
}